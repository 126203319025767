<template lang="pug">
  .welcome
    SvgKickEx.welcome__logo
    transition(name="fade", mode="out-in")
      img(v-if="show && welcomeImgName" :src="getImgUrl")
    .welcome__info
      h2 {{ $t('actions.welcome') }}
      p {{ $t('actions.coverTitle') }}. {{ $t('actions.coverDescription') }}
</template>

<script>
import { mapGetters } from 'vuex';
import SvgKickEx from '@/components/svg/SvgKickEx';

export default {
  name: 'Welcome',
  data() {
    return {
      show: false
    };
  },
  components: { SvgKickEx },
  computed: {
    ...mapGetters('user', ['welcomeImgName']),
    getImgUrl() {
      return require(`../assets/images/${this.welcomeImgName}.${this.welcomeImgName === 'signup' ? 'png' : 'svg'}`);
    }
  },
  watch: {
    welcomeImgName() {
      this.show = !this.show;
      if (!this.show) {
        this.$nextTick(() => {
          this.show = !this.show;
        });
      }
    }
  }
};
</script>

<style scoped lang="scss">
  @import '../assets/scss/variables';
  .welcome {
    background: #0CC466;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    &__logo {
      height: 5vh;
      margin-top: 10vh;
    }
    img{
      height: 40vh;
      margin-top: 10vh;
      object-fit: contain;
    }
    &__info{
      height: 20vh;
      margin-top: 5vh;
      h2 {
        font-style: normal;
        font-weight: 500;
        font-size: 40px;
        line-height: 50px;
        text-align: center;
        color: #FFFFFF;
        width: 374px;
      }
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        color: #FFFFFF;
        width: 345px;
      }
    }
  }
  // Transition animation
  .fade-enter-active,
  .fade-leave-active {
    transition: all 1s $easing;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
</style>
