<template lang="pug">
  div(:class="{ social: isSocialSignin }")
    .cover
      v-progress-circular(v-if="isSocialSignin", indeterminate, color="#66c00d", size="50")
      .cover__img-block
        Welcome
      .cover__form-block
        .form_wrap(justify-center, :class="{blocked: $route.name ==='blocked'}")
          .form_inner
            transition(name="fade", mode="out-in")
              router-view
        Footer
</template>

<script>
import { mapState } from 'vuex';
import AuthSignUp from '@/components/AuthSignUp';
import Welcome from '@/components/Welcome';
import SvgFacebook from '@/components/svg/SvgSocialFacebook';
import SvgGoogle from '@/components/svg/SvgGoogle';
import REGEXP from '@/regexp';
import Footer from '@/components/TheFooter';

export default {
  components: {
    SvgFacebook,
    SvgGoogle,
    AuthSignUp,
    Welcome,
    Footer
  },
  props: {
    signIn: { type: Boolean, default: false }
  },
  data() {
    return {
      facebookBtnActive: true,
      googleBtnActive: true,
      errors: [],
      errorsCaptcha: [],
      errorsSignup: [],
      errors2FA: [],
      showCodeInput: false,
      loginToken: '',
      email: '',
      password: '',
      captcha: '',
      showCaptchaDialog: false,
      signupFormData: null,
      captchaValid: false,
      signupBtnActive: false,
      loading: false,
      captchaLoading: false,
      showPassword: false,
      isSocialSignin: false
      // serviceIndex: ''
    };
  },
  computed: {
    ...mapState(['user']),
    mainTitle() {
      switch (this.$route.name) {
        case 'signup': return this.$t('authTitles.signup');
        case 'complete-signup':
        case 'confirm-email':
        case 'restore':
          return '';
        case 'blocked':
          return this.$t('authTitles.accountBlocked.title');
        case 'signin':
        default:
          return this.$t('authTitles.signin');
      }
    },
    rules() {
      return {
        email: v => REGEXP.email.test(v.trim()) || this.$t('validation.emailInvalid'),
        required: v => !!v.trim() || this.$t('validation.required'),
        password: v => REGEXP.password.test(v) || this.$t('validation.password', { min: 8 }),
        passwordLength: v => v.length >= 8 || this.$t('validation.passwordLength', { min: 8 }),
        max: v => v.length <= 160 || this.$t('validation.maxLength', { length: 160 })
      };
    },
    captchaId() {
      return this.$store.state.user.captchaId;
    },
    captchaImage() {
      return this.$store.state.user.captchaImage;
    },
    socialSignInErrors() {
      return this.$store.state.user.socialSignInErrors;
    }
  },
  beforeMount() {
    if (this.$route.name === 'social-auth') {
      this.isSocialSignin = true;
    }

    this.$eventHub.$on('socialSigninResolved', () => { this.isSocialSignin = false; });
  },
  beforeDestroy() {
    this.$eventHub.$off('socialSigninResolved');
  }
};
</script>

<style scoped lang="scss">
  @import '../assets/scss/variables';

  .form_wrap {
    padding: 0 20px;
    max-width: 536px;
    margin: 0 auto;
    position: relative;
    width: 100%;
    &.blocked {
      max-width: 100%;
      text-align: left;
      padding-top: 180px;

      .form_inner {
        background-color: transparent;
        box-shadow: none;
        padding: 0;
      }

      p { max-width: 553px; }
    }

    .form_inner {
      padding-top: 3vh;
      border-radius: 2px;
    }

    ::v-deep .v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
      background-color: $disabled-btn !important;
      color: $white !important;
    }

    ::v-deep .switch {
      color: $black;
      font-weight: 600;
      font-size: 18px;
      padding: 30px 0;
      margin-left: -30px;
      margin-right: -30px;

      a {
        color: $blue;
        text-decoration: none;
      }

      @media screen and (max-width: 600px) { margin: 0 -12px; }
    }

    ::v-deep  .or {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 9px;
    }

    h2 { margin-bottom: 20px; }

    @media screen and (max-width: 960px) {
      padding: 0 20px;
      width: 100% ;
      max-width: 100%;
      margin: 0 auto;
    }

    @media screen and (max-width: 530px) {
      padding: 0;
    }
    @media screen and (max-width: 600px) {
      h1 {
        font-size: 30px;
        margin-left: 0;
      }

      .form_inner {
        width: 100%;
        padding: 14px 0;
      }
    }
  }

  .cover {
    display: flex;
    justify-content: space-between;
    .v-progress-circular{
      position: fixed;
      right: 20%;
      top: 30%;
      transform: translate(0, -50%);
      z-index: 2;
      @media screen and (max-width: 800px) {
        right: 50%;
        transform: translate(50%, -50%);
      }
    }
    @media screen and (max-width: 960px) { background: $grey-background; }

    &__img-block{
      width: 55%;
      min-height: 100vh;
      height: 100vh;
      @media screen and (max-width: 800px){
        display: none;
      }
    }
    &__form-block{
      width: 45%;
      min-height: 100vh;
      height: 100vh;
      background: $white;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @media screen and (max-width: 800px){
        width: 100%;
      }
    }
  }

  .form {
    ::v-deep .v-text-field .v-input__slot {
      background-color: $white;
      border: 1px solid $light-theme-background !important;
      font-weight: 600;
      border-radius: 2px !important;
      min-height: 66px !important;
      margin-bottom: 0;
    }

    ::v-deep .v-input__icon--append .v-icon { color: $grey-light15 !important; }
  }
  ::v-deep  .v-text-field .v-input__slot{
    margin-bottom: 0;
  }
  ::v-deep .v-text-field .v-text-field__details{
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 0;
    margin-top: -3px;
  }
  ::v-deep .v-messages.error--text{
    background: #F83E4B;
    color: white!important;
    padding: 3px 10px;
  }
  .errors {
    color: $error-color;
    text-align: left;
  }

  .social {
    .cover {
      min-height: unset !important;
      background: none;
      h1 { display: none; }
    }

    .wrapper {
      position: relative;

      .v-progress-circular {
        position: absolute;
        top: calc(50% - 25px);
        left: calc(50% - 25px);
      }
    }

    .form_inner { box-shadow: none; }

    ::v-deep header,
    ::v-deep footer {
      filter: blur(5px) brightness(1.5);
    }
  }

  ::v-deep .v-card__text { text-align: left !important; }

  .v-card {
    .captcha { margin-bottom: 15px; }

    ::v-deep .v-btn,
    ::v-deep .v-input {
      width: 260px;
    }

    .close {
      width: 24px;
      min-width: 24px;
      height: 24px;
      outline: none;
      transform: rotate(45deg);
      margin: auto 0 auto auto;
      opacity: 0.54;
      cursor: pointer;
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }
  ::v-deep .v-input input {
    max-height: inherit;
    padding: 0;
  }
  // Transition animation
  .fade-enter-active,
  .fade-leave-active {
    transition: all .3s $easing;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
</style>
