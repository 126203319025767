<template lang="pug">
  footer
    .footer__block.d-flex.justify-center.align-center
      router-link(:to="{ name: 'Terms of Use' }") {{ $t('legalDocs.termsOfUseTitle')}}
      span ·
      router-link(:to="{ name: 'Privacy policy' }") {{$t('legalDocs.privacyAndPolicy')}}
</template>

<script>
export default {
  components: {
  }
};
</script>

<style lang="scss" scoped>
@import '../assets/scss/variables';

footer {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 30px;
    .footer__block {
      font-size: 12px;
      color: rgba(31, 33, 38, 0.6);
      font-weight: 400;
      text-align: center;
      @media screen and (max-width: 1200px) {
      flex-direction: column !important;
    }
      @media screen and (max-width: 798px) {
        margin-bottom: 30px;
      }
      span{
        margin-left: 5px;
        margin-right: 5px;
        @media screen and (max-width: 1200px) {
          display:none
        }
      }
      a {
        text-decoration: none;
        color: $light-theme-text;
      }
    }
}
</style>
